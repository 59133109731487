import React, { useContext, useEffect } from 'react'
import { store } from 'store/hookStore'
import { getLanguage } from 'shared-utils/i18l'
import EN from './QUESTIONNAIRE-TOOL_EN.json'
import DE from './QUESTIONNAIRE-TOOL_DE.json'

const initialValue = {
    'en': {
        ...EN,
    },
    'de': {
        ...DE,
    },
}
// SOME DICTIONARIES ARE LOADED DINAMICALLY. configs are on 'questionnaireDBMap' 
const getCurrentKeys = () => {
    let local = localStorage.getItem("locales")
    local = local ? { ...initialValue, ...JSON.parse(local) } : initialValue
    return local
}

const cache = {}

const localized = (section) => (path, variables) => msg(
    (section
        ? section + '_'
        : '') +
    path +
    (variables !== undefined && variables.isMobile !== undefined
        ? variables.isMobile
            ? '_MOBILE'
            : '_DESKTOP'
        : ''),
    variables)

export default localized


const parseMessage = (path, variables, deactiveCache) => {
    const local = getCurrentKeys()
    const language = variables.forceLang || getLanguage()
    if (cache[path] && deactiveCache === undefined) return cache[path];
    if (cache[path] === "" && deactiveCache === undefined) return "";
    const foundKey = local[language.toLowerCase()][path]

    let text = (foundKey !== undefined && foundKey.constructor === String) ? foundKey : path
    variables && Object.keys(variables).forEach(k => {
        const reg = new RegExp(`{${k}}`, 'gi')
        text = text.replace(reg, variables[k])
    })
    if (foundKey) cache[path] = text
    return text
}

export const msg = (path, variables = {}, isHtml = false, deactiveCache) =>
    isHtml ? <span dangerouslySetInnerHTML={{ __html: parseMessage(path, variables) }} /> : parseMessage(path, variables, deactiveCache)


export const LoadQuestionnaireConfigs = ({ surveyMap }) => {
    const globalState = useContext(store);
    const { dispatch } = globalState;
    const arrayToObject = (arr) => arr.reduce((prev, next) => ({ ...next, ...prev }), {})

    useEffect(() => {
        (async () => {

            // wait to load maps...
            const config = await surveyMap
            const surveys = arrayToObject(config)

            // configure new keys
            let currentDictionaries = getCurrentKeys()
            Object.keys(surveys).forEach(key => {
                const langs = arrayToObject(surveys[key].languages)
                Object.keys(langs).forEach(key => {
                    currentDictionaries[key.toLowerCase()] = ({ ...currentDictionaries[key.toLowerCase()], ...langs[key] })
                })
            })
            localStorage.setItem('locales', JSON.stringify({ ...initialValue, ...currentDictionaries }))
            // display text
            dispatch({
                type: "UPDATE_SURVEY_CONFIG_LIST",
                value: surveys
            })
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return null
}
