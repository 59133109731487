import React, { useContext, useEffect, useState } from "react";
import { store } from "store/hookStore";
import { api, http } from "shared-utils";
import { useHistory } from "react-router-dom";
import { withEventTracker, ERROR } from 'analytics/withEventTracker'
import { msg } from 'locales/Dictionary'


export const getProfileMetrics = async ({ trainingId, userId }) => {
  try {
    return await http
      .get(
        api.questionnairesProfileMetrics({
          trainingId,
          userId
        })
      )
  } catch (err) {
    return false
  }
}

const checkIfQuestionnaireExists = async ({ userId }) => {
  try {
    return await http.get(api.questionnairesByUserId(userId))
  } catch (err) {
    const error = await err
    const content = ({ ...error, message: 'checkIfQuestionnaireExists failed', logout: true })
    throw content
  }
};

const checkIfHasUserHasRelationWithTrainingAndQuestionnaire = async ({ userId, questionnaireId, trainingId }) => {
  try {
    return {
      questionnaireData: await http.get(
        api.questionnaireStatusByUserIdAndTrainingIdAndQuestionnaireId({
          userId,
          questionnaireId,
          trainingId
        })
      )
    }
  } catch (err) {
    const error = await err
    console.error(error)
    return {
      questionnaireData: false
    }
  }
};

const createUserRelationWithQuestionaire = async ({ userId, questionnaireId, trainingId }) => {
  try {
    return await http.post(
      api.questionnaireCreateNewStatusRelation({
        userId,
        questionnaireId,
        trainingId,
      })
    )
  } catch (err) {
    const error = await err
    const content = ({ ...error, message: 'createUserRelationWithQuestionaire failed' })
    return content
  }
};

const checkExpirationDate = (daysForFeedback) => {
  const currentDate = Date.now();
  const profileDate = new Date();
  const nDate = daysForFeedback.split(".");
  // const nDate = "24.06.2021".split(".");
  profileDate.setDate(nDate[0]);
  profileDate.setMonth(Number(nDate[1]) - 1);
  profileDate.setFullYear(Number(nDate[2]));
  const checkWhenExpires = new Date(profileDate.getTime());
  checkWhenExpires.setDate(checkWhenExpires.getDate() - 1);
  const remainingDays = (profileDate - currentDate) / (1000 * 3600 * 24);
  return {
    expired: currentDate > profileDate.getTime(),
    remainingDays,
    oneDayBeforeExpire: remainingDays <= 1,
  };
};

export const updateQuestionnaireStatus = async ({ userId, questionnaireId, trainingId }) => {
  try {
    return await http.post(
      api.questionnaireStatusFinish({
        userId,
        questionnaireId,
        trainingId,
      })
    )
  } catch (err) {
    const error = await err
    throw Promise.reject({ ...error, message: 'updateQuestionnaireStatus failed' })
  }
}

const redirectToExpectedPath = ({
  currentQuestionnaireStatus,
  profileReadyDateState,
  history,
  currentClient
}) => {

  const currentCustomer = currentClient ? `/${currentClient}` : "";
  const urlDownload = `${currentCustomer}/profile/download`
  const urlFeedback = `${currentCustomer}/feedback`
  const urlProcessing = `${currentCustomer}/profile/processing`
  const urlQuestionnaire = `${currentCustomer}/questionnaire`

  // if questionnaire is unfinished, it redirects to questionnaire page - except the user is at the landing pages
  if (!currentQuestionnaireStatus)
    return ([urlDownload, urlProcessing, urlFeedback].includes(window.location.pathname)) && history.push(urlQuestionnaire);


  // if questionnaire is finished and expireDate date is expired , move to download
  if (
    profileReadyDateState.remainingDays <= 0
    && currentQuestionnaireStatus
  ) return history.push(urlDownload);

  // if questionnaire is finished, expireDate date is not expired and it is not one day before expiring, move to feedbacks
  if (
    !profileReadyDateState.expired
    && currentQuestionnaireStatus
    && !profileReadyDateState.oneDayBeforeExpire
  ) return history.push(urlFeedback);

  // if questionnaire is finished, expireDate date is not expired and it is one day before expiring, move to processing
  if (
    !profileReadyDateState.expired
    && currentQuestionnaireStatus
    && profileReadyDateState.oneDayBeforeExpire
  ) return history.push(urlProcessing);

}


const CheckQuestionnaireStatus = ({ currentClient, eventTracker }) => {
  const [errorMessage, setErrorMessage] = useState()
  const history = useHistory()
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  // console.log(eventTracker)

  useEffect(() => {
    state.user.id && (async () => {
      const userId = state.user.id
      try {
        setErrorMessage('')
        // check available questionnaire and get data
        const { profileReadyDate, id: questionnaireId, trainingId } = await checkIfQuestionnaireExists({ userId })

        // check if status data is already created
        const { questionnaireData } = await checkIfHasUserHasRelationWithTrainingAndQuestionnaire({ userId, questionnaireId, trainingId })

        // if status data is missing, create it. 
        let currentQuestionnaireData = questionnaireData ? questionnaireData : await createUserRelationWithQuestionaire({ userId, questionnaireId, trainingId })

        // check if profile already has data to download. If yes, update currentQuestionData. This is required to the case of older users come back
        const profileMetrics = await getProfileMetrics({ trainingId, userId })

        currentQuestionnaireData =
          (!profileMetrics) ?
            currentQuestionnaireData :
            await updateQuestionnaireStatus({ userId, questionnaireId, trainingId });

        // get current status and expiration date 
        const currentQuestionnaireStatus = currentQuestionnaireData.status
        const profileReadyDateState = checkExpirationDate(profileReadyDate)

        dispatch({
          type: 'UPDATE_SURVEY_STATUS',
          value: { userId, questionnaireId, trainingId }
        })

        // redirect according to data combinatioin
        redirectToExpectedPath({ currentQuestionnaireStatus, profileReadyDateState, history, currentClient })
      } catch (err) {
        const error = await err
        error.logout && dispatch({
          type: 'LOGOUT'
        })
        eventTracker({
          category: ERROR, // required
          action: `${currentClient} logout: no questionnaire associated`, // required
          label: err
        })
        setErrorMessage(
          msg('TUM_LOGIN_LABEL_FAILURE')
        )
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user.id]);

  return (errorMessage ? <div style={{ background: '#c00', color: '#fff', padding: '.5rem', fontWeight: 'bold' }} > {errorMessage} </div> : null);
};

export default withEventTracker(CheckQuestionnaireStatus);
