import React from 'react'
import { Container, Row, Col, H2 } from "shared-components"
import styles from './Header.module.scss'

const Header = ({
    title,
    children,
    productName = ''
}) => (

    <Row className={styles.header}>
        <Container>
            {productName && 
                <Row className={styles.header__product_name}>
                    <Col> {productName} </Col>
                </Row>
            }
            {title && 
                <Row>
                    <Col>
                        <H2> {title} </H2>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    {children}
                </Col>
            </Row>
        </Container>
    </Row>
)

export default Header