import React, { useContext } from 'react'
import { store } from 'store/hookStore'
import { Container } from "shared-components"
import styles from './Logout.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

export const Logout = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const token = state && state.user && state.user.access_token
    const logout = e => {
        e.preventDefault()
        dispatch({
            type: 'LOGOUT'
        })
    }

    return token ? (
        <div>
            <Container style={{ position: 'relative'}}>
                <div className={styles.logout}>
                    <button onClick={logout} className={styles.logout__button}>
                        logout &nbsp;&nbsp;
                         <FontAwesomeIcon icon={faSignOutAlt} />
                    </button>
                </div>
            </Container>
        </div>
    ) : null
}

export default Logout