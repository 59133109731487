// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Login_button__2hU54 {\n  width: 100%;\n  float: right;\n}", ""]);
// Exports
exports.locals = {
	"button": "Login_button__2hU54"
};
module.exports = exports;
