import config from "./configs/app.config";
import { hookeStoreName } from "../../packages/questionnaire/src/store/hookStore";
const env = process.env.REACT_APP_CUSTOM_NODE_ENV || process.env.NODE_ENV;
const envConfig = config[env];
import { setCookie, getCookie } from "./cookies";

const userTokenAdm = "everskill-user-token";
const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const api =
  (method) =>
  (path, body, options = {}) => {
    const token = getCookie(userTokenAdm);
    const userToken = token ? JSON.parse(token) : "";
    window.userToken = userToken;

    const payload =
      options.formData ||
      JSON.stringify({
        ...(body || {}),
      });

    const retryCall = async (url, noBodyResponse) => {
      const newToken = await fetchAsync(
        `${
          process.env.API_HOST || envConfig.BASE_URL
        }/oauth/token?grant_type=refresh_token&refresh_token=${
          window.userToken.refresh_token
        }`,
        {
          method: "POST",
          headers: {
            Authorization: `Basic ${window.btoa(
              envConfig.CLIENT_ID + ":" + envConfig.CLIENT_SECRET
            )}`,
          },
          ...(body || options.formData
            ? {
                body: payload,
              }
            : {}),
        },
        noBodyResponse,
        true
      );

      window.userToken = newToken;
      setCookie(userTokenAdm, JSON.stringify(newToken));
      await delay(300);
      const result = await http[method.toLowerCase()](url, body, {
        ...options,
        newTry: true,
      });
      return result;
    };
    const fetchAsync = (url, headers, noBodyResponse) => {
      return fetch(url, headers).then(async (response) => {
        switch (response.status) {
          case 401:
            if (
              !options.newTry &&
              window.userToken &&
              window.userToken.refresh_token
            ) {
              return await retryCall(url, noBodyResponse);
            }

            localStorage.removeItem(hookeStoreName);
            options.reload === undefined &&
              setTimeout(() => location.reload(), 100);

            window._401_LOGOUT && window.dispatchEvent(window._401_LOGOUT);
            break;
          case 400:
          case 403:
          case 404:
          case 409:
          case 500:
            const error = await response.json();
            throw { ...error, status: response.status };
          default:
            if (options.responseType === "blob") {
              const blob = await response.blob();
              return URL.createObjectURL(blob);
            }
            return noBodyResponse || (method === "DELETE" && !noBodyResponse)
              ? {}
              : (async () => {
                  return await response.json().catch((err) => {});
                })();
        }
      });
    };

    return fetchAsync(
      `${
        (!/^http/.exec(path)
          ? process.env.API_HOST || envConfig.BASE_URL
          : "") + path
      }`,
      {
        method,
        headers: {
          Authorization: options.noToken
            ? `Basic ${window.btoa(
                envConfig.CLIENT_ID + ":" + envConfig.CLIENT_SECRET
              )}`
            : `Bearer ${userToken && userToken.access_token}`,
          Accept: options.accept || "application/json",
          ...(options.contentType !== null
            ? { "Content-Type": options.contentType || "application/json" }
            : {}),
        },
        ...(body || options.formData
          ? {
              body: payload,
            }
          : {}),
      },
      options.noBodyResponse
    );
  };

export const http = {};
http.retrieveToken = () => JSON.parse(getCookie(userTokenAdm)).access_token;
http.retrieveBradingImage = () => envConfig.BRANDING_ASSETS;
http.retrieveCategoryIcon = () => envConfig.ASSETS;
http.retrieveGoalIcon = () => envConfig.ASSETS;
http.useBaseUrl = (value) => `${envConfig.BASE_URL}/${value}`;
http.get = api("GET");
http.post = api("POST");
http.patch = api("PATCH");
http.delete = api("DELETE");
http.put = api("PUT");
http.withAPIPath = (value) =>
  `${process.env.API_HOST || envConfig.BASE_URL}/${value.replace(/^\//g, "")}`;

export default http;
