import React, { Fragment, useContext } from 'react'
import { Container, Row, Menu } from "shared-components"
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher'
import Logout from 'components/Logout'
import { store } from 'store/hookStore'
import styles from './Layout.module.scss'

export const Layout = ({
    children,
    theme
}) => {
    const globalState = useContext(store);
    const { state } = globalState;


    return (
        <Fragment>

            {
                !state.layout.fixedLanguage && state.layout.showLanguageSwitchter && <LanguageSwitcher />
            }
            <Logout />
            <Container>
                <Menu
                    logo={<img className={styles.logo__img} src={state.theme.logo} alt='logo' />}
                >
                </Menu>

            </Container>
            <Row>
                {children}
            </Row>
            <Container>
                <Row>
                    {/* Footer */}
                </Row>
            </Container>
        </Fragment>
    )
}

export default Layout