export const setCookie = (cookieKey, cookieValue, expirationDays, path) => {
    let cookie = ''
    let expires = '';
    if (expirationDays) {
      const date = new Date();
      date.setDate(date.getDate()+expirationDays);
      expires = `; expires=${date.toUTCString()}"`;
    }
    cookie = cookieKey + "=" + (cookieValue || "") + expires + "; path=" + (path ? path : '/');
    document.cookie = cookie
    return cookie;
  }
  
export const getCookie = (cookieKey) => {
    let cookieName = `${cookieKey}=`;
    let cookieArray = document.cookie.split(';');
    for (let cookie of cookieArray) {
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
}

export default { getCookie,  setCookie }