/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react'
import { store } from 'store/hookStore'
import Header from 'components/Header'
import { msg } from 'locales/Dictionary'
import { Container, Row, Col, SectionTitle, Button, InputPlain } from "shared-components"
import { api, http, setCookie, emailValidation } from 'shared-utils'
import H2Header from 'components/H2Header'
import styles from './Login.module.scss'
import { useEffect } from 'react'
import { withEventTracker, LOADPAGE, ACTION, ERROR } from 'analytics/withEventTracker'

// TODO: abstract this component and move to an shared folder
export const Login = withEventTracker(({ eventTracker, customer }) => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const localizeMsg = (text) => msg(`${state.theme.localize}${text}`)

    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [psw, setPassword] = useState('')

    const getUserData = async () => {
        return (
            http.get(api.userDetails).then(data => {
                dispatch({
                    type: 'USER_UPDATE',
                    value: {
                        ...data
                    }
                })
            })
        )
    }

    const submitLoginData = async () => {
        return http.post(api.oauthUser, {}, {
            noToken: true,
            reload: false,
            contentType: "application/x-www-form-urlencoded",
            formData: `grant_type=password&password=${encodeURIComponent(psw)}&username=${encodeURIComponent(email)}`
        }).then(data => {
            const { access_token, refresh_token } = data
            setCookie('everskill-user-token', JSON.stringify({
                access_token,
                refresh_token
            }))
            dispatch({
                type: 'USER_UPDATE',
                value: {
                    access_token,
                    refresh_token,
                    email
                }
            })
            return data
        })
    }

    const login = () =>
        submitLoginData()
            .then(data => {
                getUserData()
                eventTracker && eventTracker({
                    category: ACTION, // required
                    action: `${customer} user logged in`, // required
                })
                return data
            })
            .catch(err => {
                setError(msg(localizeMsg('LOGIN_LABEL_FAILURE')))
                console.error(err)
                eventTracker && eventTracker({
                    category: ERROR, // required
                    action: `${customer} user cant login`, // required
                    label: err
                })
            })

    const submitForm = e => {
        e.preventDefault()
        login(email, psw)

    }

    // force state tree cleaning if not logged
    useEffect(() => {
        dispatch({
            type: 'LOGOUT'
        })
    }, [dispatch])

    useEffect(() => {
        dispatch({
            type: 'UPDATE_LAYOUT',
            value: {
                showLanguageSwitchter: true
            }
        })
        eventTracker && eventTracker({
            category: LOADPAGE, // required
            action: `login page`, // required
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Header>
                <Row>
                    <Col size={5}>
                        <H2Header>
                            {localizeMsg('LOGIN_SCREEN_TITLE')}
                        </H2Header>
                    </Col>
                </Row>
            </Header>

            <Container>
                <form onSubmit={submitForm}>
                    <Row>
                        <Col size={5}>
                            <Row>
                                <Col>
                                    <SectionTitle>
                                        {localizeMsg('LOGIN_CONTENT_TEXT')}
                                    </SectionTitle>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputPlain
                                        placeholder={localizeMsg('LOGIN_EMAIL_PLACEHOLDER')}
                                        onChange={e => setEmail(e.target.value)}
                                        invalid={!!email && !emailValidation(email)}
                                        borderBottom={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <p />
                            </Row>
                            <Row>
                                <Col>
                                    <InputPlain
                                        placeholder={localizeMsg('LOGIN_PASSWORD_PLACEHOLDER')}
                                        onChange={e => setPassword(e.target.value)}
                                        type={'password'}
                                        borderBottom={true}
                                    />
                                    {error && <p style={{ color: '#c00' }}><br />{error}<br /></p>}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <a
                                            href="https://account.leadershipprofile.lll.tum.de/forgot-password"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {localizeMsg('LOGIN_FORGOT_PASSWORD_BUTTON')}
                                        </a>
                                        <br />
                                        <br />
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col />
                        <Col size={2}>
                            <Button className={styles.button}>{msg('GENERAL_LOGIN')}</Button>
                        </Col>
                    </Row>
                </form>
            </Container>
        </>
    )
})

export default Login

