import logo from "./logos/tum.png";
import { protect } from "containers/AuthorizedArea";
import questionnaireDBMap from "locales/questionnaireDBMap";

import { asyncComponent } from "shared-components";

const AsyncImport = (path) =>
  asyncComponent(() => {
    return {
      LandingPage: import("containers/_TUM_SURVEY/LandingPage"),
      Questionnaire: import("containers/_TUM_SURVEY/Questionnaire"),
      Feedback: import("containers/_TUM_SURVEY/Feedback"),
      DownloadProfile: import("containers/_TUM_SURVEY/DownloadProfile"),
      QuestionnaireFeedbackKey: import(
        "containers/_TUM_SURVEY/QuestionnaireFeedbackKey"
      ),
      QuestionnaireFeedbackKeyLandingPage: import(
        "containers/_TUM_SURVEY/QuestionnaireFeedbackKeyLandingPage"
      ),
      QuestionnaireFeedbackKeySuccess: import(
        "containers/_TUM_SURVEY/QuestionnaireFeedbackKeySuccess"
      ),
      PDFLayoutEngine: import(
        "containers/_TUM_SURVEY/PDFGenerator/PDFLayoutEngine/PDFLayoutEngine"
      ),
      Expired: import("containers/_TUM_SURVEY/Expired"),
    }[path];
  });

export const routes = [
  {
    paths: [
      "/tum_survey/pdf-layout-engine/:lang",
      "/tum_survey/pdf-layout-engine/:lang/:userId/:trainingId/:firstName",
      "/tum_survey/pdf-layout-engine/:lang/:userId/:trainingId/:firstName/:lastName",
    ],
    Component: AsyncImport("PDFLayoutEngine"),
    noLayout: true,
  },
  {
    paths: ["/feedback", "/:customer/feedback"],
    Component: protect(AsyncImport("Feedback")),
  },
  {
    paths: ["/questionnaire", "/:customer/questionnaire"],
    Component: protect(AsyncImport("Questionnaire")),
  },
  {
    paths: ["/questionnaire", "/:customer/questionnaire/:page"],
    Component: protect(AsyncImport("Questionnaire")),
  },
  {
    paths: [
      "/questionnaire/:survey_state",
      "/:customer/questionnaire/:survey_state",
    ],
    Component: protect(AsyncImport("Questionnaire")),
  },

  {
    paths: ["/download-profile", "/:customer/download-profile"],
    Component: protect(AsyncImport("DownloadProfile")),
  },
  {
    paths: [
      "/processing-profile/:processing",
      "/:customer/profile/:processing",
    ],
    Component: protect(AsyncImport("DownloadProfile")),
  },
  {
    paths: [
      "/feedbackKey/:feedbackKey",
      "/:customer/feedbackKey/:feedbackKey",
      // temporary
      "/questionnaire/:survey/feedbackKey/:feedbackKey",
      "/:customer/questionnaire/:survey/feedbackKey/:feedbackKey",
    ],
    Component: AsyncImport("QuestionnaireFeedbackKeyLandingPage"),
  },
  {
    paths: [
      "/questionnaire/:survey/feedbackKey/:feedbackKey/evaluate",
      "/:customer/questionnaire/:survey/feedbackKey/:feedbackKey/evaluate",
    ],
    Component: AsyncImport("QuestionnaireFeedbackKey"),
  },
  {
    paths: [
      "/questionnaire/:survey/feedbackKey/:feedbackKey/evaluate/:page",
      "/:customer/questionnaire/:survey/feedbackKey/:feedbackKey/evaluate/:page",
    ],
    Component: AsyncImport("QuestionnaireFeedbackKey"),
  },
  {
    paths: [
      "/questionnaire/:survey/feedbackKey/:feedbackKey/success",
      "/:customer/questionnaire/:survey/feedbackKey/:feedbackKey/success",
    ],
    Component: AsyncImport("QuestionnaireFeedbackKeySuccess"),
  },
  {
    paths: ["/questionnaire/expired", "/:customer/expired"],
    Component: protect(AsyncImport("Expired")),
  },

  {
    paths: ["/", "/:customer/landing-page", "/:customer"],
    Component: protect(AsyncImport("LandingPage")),
  },
];

const getMapConfigs = questionnaireDBMap.map(async (item) => {
  const pages = await import(`locales/${item.dataMap}`).then(
    (data) => data.default
  );

  const languages = await Promise.all(
    Object.keys(item.lang).map(async (key) => {
      return await import(`locales/${item.lang[key]}`).then((data) => ({
        [key]: data.default,
      }));
    })
  );

  return {
    [item.name]: {
      ...item,
      pages,
      languages,
    },
  };
});

export default {
  logo,
  localize: "TUM_",
  productName: "TUM_PRODUCT_NAME",
  routes,
  checkUserQuestionnaireStatus: false,
  surveyMap: Promise.all(getMapConfigs),
};
