// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@media (max-width: 768px) {\n  .Layout_logo__img__2I6uL {\n    max-width: 11em;\n  }\n}", ""]);
// Exports
exports.locals = {
	"logo__img": "Layout_logo__img__2I6uL"
};
module.exports = exports;
