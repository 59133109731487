// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".H2Header_h2__3Uy8O {\n  margin: 0;\n  padding: 0;\n  font-size: 2.5rem;\n  font-weight: 100;\n  color: #333;\n}", ""]);
// Exports
exports.locals = {
	"h2": "H2Header_h2__3Uy8O"
};
module.exports = exports;
