import React, { createContext, useReducer, useEffect } from "react";
import theme from "themes/tum_survey";
import { merge } from "shared-utils";

export const hookeStoreName = "hookState";

const firstState = {
  customer: "tum_survey",
  user: {
    access_token: null,
    refresh_token: null,
    email: null,
    lastName: "",
  },
  theme: {
    ...theme,
    baseURL: "/tum_survey",
  },
  surveyConfigList: {},
  surveyStatus: {},
  layout: {
    showLanguageSwitchter: true,
  },
  survey: {
    page: 1,
    finished: false,
    currentState: "open",
    questions: {},
  },
  training: {
    id: null,
  },
  pdfProfile: {
    totalPages: 0,
    current: 1,
  },
};

const initialState = localStorage.getItem(hookeStoreName)
  ? { ...firstState, ...JSON.parse(localStorage.getItem(hookeStoreName)) }
  : Object.assign({}, firstState);

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "THEME_UPDATE":
        return { ...state, theme: action.value };

      case "CUSTOMER_UPDATE":
        return { ...state, customer: action.value };

      case "UPDATE_SURVEY_CONFIG_LIST":
        return { ...state, surveyConfigList: action.value };

      case "UPDATE_SURVEY_STATUS":
        return { ...state, surveyStatus: action.value };

      case "USER_UPDATE":
        return { ...state, user: merge(state.user, action.value) };
      case "UPDATE_LAYOUT":
        return { ...state, layout: merge(state.layout, action.value) };

      case "UPDATE_SURVEY":
        return { ...state, survey: merge(state.survey, action.value) };

      case "UPDATE_SURVEY_QUESTION":
        return { ...state, survey: merge(state.survey, action.value) };
      case "UPDATE_SURVEY_QUESTION_LIST":
        return { ...state, survey: { ...state.survey, ...action.value } };

      case "UPDATE_TRAINING":
        return { ...state, training: merge(state.training, action.value) };

      case "UPDATE_PDF_PROFILE":
        return { ...state, pdfProfile: merge(state.pdfProfile, action.value) };

      case "UPDATE_PDF_PROFILE_GRAPH":
        return {
          ...state,
          pdfProfile: { ...state.pdfProfile, ...action.value },
        };

      case "LOGOUT":
        const showLanguageSwitchter =
          !Boolean(state.layout.showLanguageSwitchter) === false;
        const layout = { ...firstState.layout, showLanguageSwitchter };
        return {
          ...state,
          user: {},
          layout,
          training: { id: null },
          pdfProfile: {},
          surveyStatus: {},
          survey: {},
          surveyConfig: {},
        };
      default:
        return state;
    }
  }, initialState);

  delete state.routes;
  const stringState = JSON.stringify(state);
  useEffect(() => {
    localStorage.setItem(hookeStoreName, stringState);
  }, [stringState]);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
