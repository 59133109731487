import cookie from "react-cookies"

const EN = 'en', DE = 'de'

export const calculateLanguageByNavigator = () => {
    switch (navigator.language.split('-')[0]) {
        case EN:
            return EN
        default:
            return DE
    }
}
export const setLanguage = (lang) => cookie.save('lang', lang, { path: '/' })
export const getLanguage = () => {
    const cookies = cookie.loadAll();
    switch (cookies['lang']) {
        case EN:
            return EN
        case undefined:
            const currentLanguage = window.location.search.split('languageCode=')[1]
            const lang = currentLanguage ? currentLanguage.toLowerCase() : DE
            setLanguage(lang)
            return lang
        default:
            return DE
    }
}
export const changeLanguage = (lang) => {
    setLanguage(lang)
    window.location.reload()
}
