const siteID = "QTOOL";

let ACTION;
let ERROR;
let LOADPAGE;

const checkSymbolCompatibility = Symbol("symbolCompatibility");

// workaround to prevent Firefox < 60
if (!!checkSymbolCompatibility.description) {
  ACTION = Symbol("ACTION");
  ERROR = Symbol("ERROR");
  LOADPAGE = Symbol("LOADPAGE");
} else {
  ACTION = { description: "ACTION" };
  ERROR = { description: "ERROR" };
  LOADPAGE = { description: "LOADPAGE" };
}

export { ACTION, ERROR, LOADPAGE };

export const normalizeChars = (word) => {
  const ac = "ãáäâàãéëêèíïîìóöôòõúüûùçñýÿÁÄÂÀÃÉËÊÈÍÏÎÌÓÖÔÒÕÚÜÛÙÇÑÝ";
  const dc = "aaaaaaeeeeiiiiooooouuuucnyyAAAAAEEEEIIIIOOOOOUUUUCNY";
  const removeChars = new RegExp("[^\\w\\d\\s]", "gi");
  for (var co = 0; co < ac.length; co++) {
    word = word.replace(ac.charAt(co), dc.charAt(co));
  }
  return word
    .replace(/\s{1,}/gi, "_")
    .replace(removeChars, "")
    .toLowerCase()
    .replace(/_{2,}/gi, "_");
};

const googleTraking = ({ categoryInfo, actionInfo, labelInfo, debbug }) => {
  if (window.ga && window.ga.getAll) {
    const tracker = window.ga.getAll()[0];
    if (tracker) {
      tracker.send("event", categoryInfo, actionInfo, labelInfo);
      debbug && console.log({ ga: true, categoryInfo, actionInfo, labelInfo });
    }
  } else {
    debbug && console.info({ ga: false, categoryInfo, actionInfo, labelInfo });
  }
};

export const eventTracker = ({ userUuid = "" }) => {
  const debbug = false;
  return ({
    category = "", // required
    action = "", // required
    label = "",
  }) => {
    // check if action is avaiable
    if (
      // workaround to prevent Firefox < 60
      !!checkSymbolCompatibility.description &&
      // check if category is one of the expected
      ![ACTION, ERROR, LOADPAGE].includes(category)
    ) {
      console.error(
        `${
          category.description || category || "undefined"
        } is not defined as a valid category. Please, check if it is a valid option or import it from the constants list`
      );
      return false;
    }
    // if error is an object convert it to string
    const errorHandler = (error) =>
      error.constructor === Object ? JSON.stringify(error) : error;
    // format text before send it.
    const labelInfo = `${userUuid} ${
      !!label ? "| " + errorHandler(label) : ""
    }`.trim();
    const categoryInfo = normalizeChars(
      `${siteID} ${category.description}`
    ).toUpperCase();
    const actionInfo = normalizeChars(action.replace(/everskill/gi, ""));

    // put uuid as global so index.html can access this information on critical error cases
    window.userUUID = userUuid || null;
    // ga
    googleTraking({ categoryInfo, actionInfo, labelInfo, debbug });

    return true;
  };
};

export default eventTracker;
