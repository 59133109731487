import React, { useContext } from 'react';
import eventTracker from 'analytics/eventTracker'
import { store } from 'store/hookStore'

export const withEventTracker = (Wrapped) => (nProps) => {
    const globalState = useContext(store);
    const { state } = globalState;
    return <Wrapped
        {...nProps}
        eventTracker={eventTracker({
            userUuid: state && state.user && state.user.uuid
        })}
    />
}

export * from 'analytics/eventTracker'
export default withEventTracker
