// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Header_header__1v0yI {\n  border-top: 1px solid var(--darkgrey);\n  background-color: var(--grey);\n  padding: 3rem 0 2rem 0;\n  min-height: 13rem;\n  margin-bottom: 2rem;\n  box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.2);\n  position: relative;\n  z-index: 9;\n  overflow: hidden;\n}\n@media (max-width: 768px) {\n  .Header_header__1v0yI {\n    min-height: auto;\n  }\n}\n.Header_header__product_name__18fzN {\n  position: absolute;\n  top: 1rem;\n  text-transform: uppercase;\n  width: 50%;\n}\n.Header_header__product_name__18fzN * {\n  font-family: \"Barlow\", sans-serif !important;\n  font-size: 14px !important;\n  font-weight: bold !important;\n  color: #444;\n}", ""]);
// Exports
exports.locals = {
	"header": "Header_header__1v0yI",
	"header__product_name": "Header_header__product_name__18fzN"
};
module.exports = exports;
