export const normalizeChars = (word) => {
    const ac = "ãáäâàãéëêèíïîìóöôòõúüûùçñýÿÁÄÂÀÃÉËÊÈÍÏÎÌÓÖÔÒÕÚÜÛÙÇÑÝ";
    const dc = "aaaaaaeeeeiiiiooooouuuucnyyAAAAAEEEEIIIIOOOOOUUUUCNY";
    const removeChars = new RegExp('[^\\w\\d\\s]', 'gi')
    for (var co = 0; co < ac.length; co++) {
        word = word.toString().replace(ac.charAt(co), dc.charAt(co));
    }
    return word
        .replace(/\s{1,}/gi, '_')
        .replace(removeChars, '').toLowerCase()
        .replace(/_{2,}/gi, '_');
}

export default normalizeChars