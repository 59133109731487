export const api = {};

/*##ADMIN Endpoints##*/
api.admin = "/admin";
api.adminOauthUser = `${api.admin}/oauth/user`;
api.admUsers = `${api.admin}/users`;
api.fetchAllEditors = `${api.users}/ROLE_EDITOR`;
api.fetchAllAccountOwners = `${api.users}/ROLE_ACCOUNT_OWNER`;
api.getUserByUuid = (uuid) => `${api.users}/${uuid}`;

/*##CUSTOMER##*/
api.customers = `${api.admin}/customers`;
api.customerById = (customerId) => `${api.customers}/${customerId}`;
api.templatesByCustomerId = (customerId) =>
  `${api.customerById(customerId)}/templates`;
api.fetchEditors = (customerId) => `${api.customerById(customerId)}/editors`;
api.trainingsByCustomerId = (customerId) =>
  `${api.customerById(customerId)}/trainings`;
api.feedTemplatesByCustomerId = (customerId) =>
  `${api.customerById(customerId)}/feedtemplates`;
api.cobrandingByCustomerId = (customerId) =>
  `${api.customerById(customerId)}/brandings`;
api.accountOwnerByCustomerId = (customerId) =>
  `${api.customerById(customerId)}/accountowners`;

/*##TEMPLATE##*/
api.templateById = (id) => `${api.admin}/templates/${id}`;
api.libraryGoalByTemplateIdAndLibraryGoalId = ({ templateId, libraryGoalId }) =>
  `/admin/templates/${templateId}/goals/${libraryGoalId}`;
api.createCustomGoal = (id) => `${api.templateById(id)}/goals`;
api.finalizeTemplate = (id) => `${api.templateById(id)}/goals/copy`;
api.submitGoalToEditors = (templateId) =>
  `${api.templateById(templateId)}/goals/editor/trigger-review-mail`;
api.submitGoalToCETeam = (templateId) =>
  `${api.templateById(templateId)}/goals/admin/trigger-review-mail`;
api.connectLibraryGoalToTemplate = ({ templateId, libraryGoalId }) =>
  `/admin/templates/${templateId}/library-goals/${libraryGoalId}`;

/*##TRAINING##*/
api.trainingById = (trainingId) => `${api.admin}/trainings/${trainingId}`;
api.trainingConnectUser = ({ trainingId, userId }) =>
  `${api.trainingById(trainingId)}/users/${userId}`;
api.preTrainingQuestionnaire = `${api.admin}/pre-trainings/responses`;

/* ##PDF SERVICES ## */
api.generatePDF = ({ trainingId, userId }) =>
  `/admin/questionnaires/generate/questionnaire-profiles/trainings/${trainingId}/users/${userId}`;

/*##TRAINING SEARCH##*/
api.activeTrainingsByCustomerId = (customerId) => {
  return `/v2/trainings/search?sortBy=startTimestamp&sortOrder=ASC&size=300&from=0&customerId=${customerId}`;
};
/*##GOAL##*/
api.goals = `${api.admin}/goals`;
api.goalById = (id) => `${api.goals}/${id}`;
api.stepsByGoalId = (goalId) => `${api.goals}/${goalId}/steps`;
api.stepByGoalIdAndStepId = ({ goalId, stepId }) =>
  `${api.goals}/${goalId}/steps/${stepId}`;

/*##USER##*/
api.oauthUser = `/oauth/token`;
api.userDetails = "/v2/oauth/user";
api.userById = (userId) => `${api.admin}/users/${userId}`;

// GOAL LIBRARY
/*##LIBRARY GOALS##*/
api.libraryGoals = `${api.admin}/library-goals`;
api.libraryGoalById = (id) => `${api.libraryGoals}/${id}`;
api.libraryGoalStepsByLibraryGoalId = (libraryGoalid) =>
  `${api.libraryGoals}/${libraryGoalid}/library-steps`;

/*##LIBRARY GOAL CATEGORY##*/
api.libraryGoalCategories = `${api.admin}/library-goal-categories`;
api.libraryGoalCategoryId = (id) => `${api.libraryGoalCategories}/${id}`;
api.libraryGoalCategoryIconUpload = (id) =>
  `${api.libraryGoalCategories}/upload/categoryIcon/${id}`;
api.connectLibraryGoalCategoryAndLibraryGoal = ({
  libraryGoalCategoryId,
  libraryGoalId = "",
}) =>
  `${api.libraryGoalCategories}/${libraryGoalCategoryId}/library-goals/${libraryGoalId}`;

/*##LIBRARY GOAL STEPS##*/
api.libraryGoalSteps = `${api.admin}/library-steps`;

/*##Access Codes##*/
api.accessCodeStatus = (code) => `${api.admin}/access-codes/${code}/status`;
api.accessCodePrint = (numberOfAccessCodes) =>
  `${api.admin}/access-codes/${numberOfAccessCodes}/print`;
api.moveAccessCodesBetweenTrainings = ({ currentTrainingId, newTrainingId }) =>
  `${api.admin}/access-codes/${currentTrainingId}/move/${newTrainingId}`;
api.accessCodeGenerator = (numberOfAccessCodes) =>
  `${api.admin}/access-codes/${numberOfAccessCodes}`;

/*## QUESTIONNAIRE ##*/
api.questionnaires = `${api.admin}/questionnaires`;
api.questionnairesByUserId = (userId) =>
  `${api.admUsers}/${userId}/questionnaires`;
api.questionnairesSendInvitesByTrainingId = (trainingId) =>
  `${api.admin}/trainings/${trainingId}/invite-peers`;
api.questionnairesGetPeersByTrainingAndUserId = ({ trainingId, userId }) =>
  `${api.admin}/trainings/${trainingId}/users/${userId}/peers`;
api.questionnairesGetPeerByFeedBackKey = (feedbackKey) =>
  `${api.admin}/peers/feedback-key/${feedbackKey}`;

api.questionnaireTrainingAndUserId = ({ brand, trainingId, userId }) =>
  `${api.questionnaires}/${brand}/trainings/${trainingId}/users/${userId}`;
api.questionnaireResponsesByTrainingAndUserId = ({
  brand,
  trainingId,
  userId,
  responseId = "",
}) =>
  `${api.questionnaireTrainingAndUserId({
    brand,
    trainingId,
    userId,
  })}/responses${responseId}`;

api.questionnaireStatusByUserIdAndTrainingIdAndQuestionnaireId = ({
  userId,
  questionnaireId,
  trainingId,
}) =>
  `${api.questionnaires}/${questionnaireId}/users/${userId}/trainings/${trainingId}/status`;

api.questionnaireStatusFinish = ({ userId, questionnaireId, trainingId }) =>
  `${api.questionnaires}/${questionnaireId}/users/${userId}/trainings/${trainingId}/finish`;

api.questionnaireCreateNewStatusRelation = ({
  userId,
  questionnaireId,
  trainingId,
}) =>
  `${api.questionnaires}/${questionnaireId}/users/${userId}/trainings/${trainingId}`;

api.questionnairesGetGeneratedProfile = ({
  trainingId,
  userId,
  languageCode,
}) =>
  `/admin/questionnaires/fetch/questionnaire-profiles/${trainingId}/${userId}/language/${languageCode.toUpperCase()}`;
api.questionnairesUploadProfile = ({ trainingId, userId, languageCode }) =>
  `${
    api.questionnaires
  }/upload/questionnaire-profiles/${trainingId}/${userId}/language/${languageCode.toUpperCase()}`;
api.questionnairesProfileMetrics = ({ trainingId, userId }) =>
  `${api.questionnaires}/generate/profile-metrics/${trainingId}/${userId}`;
api.questionnairesProfileMetricsPartner = ({ partner, trainingId, userId }) =>
  `${api.questionnaires}/${partner}/profile-metrics/trainings/${trainingId}/users/${userId}`;

/*##V2 Endpoints##*/
api.trainingsV2 = "/v2/trainings";
api.setup = (uuid) => `${api.trainingsV2}/${uuid}/setup`;
api.location = (uuid) => `${api.trainingsV2}/${uuid}/location`;
api.cobrandingTypes = `${api.trainingsV2}/cobranding-types`;
api.trainingLoginTypes = `${api.trainingsV2}/login-types`;
api.trainingCodes = (uuid) => `${api.trainingsV2}/${uuid}/codes`;

api.users = "/v2/users";
api.userByUUID = (uuid) => `/v2/users/${uuid}`;
api.resetUserByUUID = (uuid) => `/v2/users/${uuid}/reset`;
api.userByEmail = (email) => `/v2/users/by-email-address/?email=${email}`;

api.posts = "/v2/posts";
api.createPostsFromFeedTemplate = "/v2/posts/create-from-feed-template";

api.kcItems = "/v2/kc-items";
api.itemsToAttachments = "/v2/items-to-attachments";
api.attachments = `/v2/attachments`;

api.goalStepsCategories = "/v2/steps-categories";

api.feedTemplates = "/v2/feed-templates";
api.feedTemplateItems = "/v2/feed-template-items";

api.brandings = "/v2/brandings";

api.passwordResetStart = `/v2/password-reset/start`;

api.userJourney = `/v2/users-journey`;

/*##Legacy Endpoints##*/
api.trainings = "/trainings";
api.sendWelcomeMailForUser = ({ trainingId, userId }) =>
  `/trainings/${trainingId}/users/${userId}/send-user-invitation`;

// NEW TUM ENDPOIINTS

api.questionnaireByUserId = (userId) => `/admin/questionnaires/users/${userId}`;
api.questionnaireProgress = ({ userId, trainingId }) =>
  `/admin/questionnaires/self-evaluation/trainings/${trainingId}/users/${userId}/progress`;
api.questionnaireCreateRelationBetweenUserAndQuestionnaire = ({
  trainingId,
  userId,
  questionnaireId,
}) =>
  `/admin/questionnaires/${questionnaireId}/users/${userId}/trainings/${trainingId}`;
api.questionnaireUserReponses = ({ userId, trainingId }) =>
  `/admin/questionnaires/self-evaluation/trainings/${trainingId}/users/${userId}/responses`;
api.questionnaireStatus = ({ questionnaireId, trainingId, userId }) =>
  `/admin/questionnaires/${questionnaireId}/users/${userId}/trainings/${trainingId}/status`;
api.questionnaireFinish = ({ trainingId, userId, questionnaireId }) =>
  `/admin/questionnaires/${questionnaireId}/users/${userId}/trainings/${trainingId}/finish`;

api.questionnaireSelfEvalutaionRequestPeers = ({
  userId,
  trainingId,
  surveyId,
}) =>
  `/admin/questionnaires/${surveyId}/trainings/${trainingId}/users/${userId}/invite`;

api.questionnaireSelfEvalutaionGetPeers = ({ userId, trainingId }) =>
  `/admin/questionnaires/trainings/${trainingId}/users/${userId}/peers`;
// `/admin/questionnaires/trainings/${trainingId}/users/${userId}/invite`;

api.peerFeedBackProgress = (feedbackKey) =>
  `/admin/peers/feedback-key/${feedbackKey}/progress`;
api.peerFeedBackDetails = (feedbackKey) =>
  `/admin/peers/feedback-key/${feedbackKey}/details`;
api.peerFeedBackResponses = (feedbackKey) =>
  `/admin/peers/feedback-key/${feedbackKey}/responses`;
api.peerFeedBackFinish = (feedbackKey) =>
  `/admin/peers/feedback-key/${feedbackKey}/finish`;
export default api;
