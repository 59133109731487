import React from 'react'
import styles from './H2Header.module.scss'
import PropTypes from 'prop-types';

export const H2Header = ({         
    children, 
    className = '', 
    type = 'primary', 
    ...rest 
} ) => {
    return <h2  {...rest} className={`${styles.h2} ${styles[type]} ${className} `}>{ children } </h2>}


H2Header.propTypes = {
    /**  custom class */
    className: PropTypes.string,
    /** expected text/Component */ 
    children:  PropTypes.node.isRequired,

}

H2Header.defaultProps = {
    type:  'primary',
}

export default H2Header;


