import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from "react-router-dom";
import Layout from 'containers/Layout'
import styles from 'styles/main.module.scss'
import { StateProvider } from 'store/hookStore'
import { setCookie } from 'shared-utils'
// config files
import tum_survey from 'themes/tum_survey'
import { LoadQuestionnaireConfigs } from 'locales/Dictionary'
import CheckQuestionnaireStatus from 'utils/CheckQuestionnaireStatus'
const withLayout = (Comp, noLayout) => (props, x) => {
    const { match: { params: { customer } } } = props
    const theme = customer || 'default'
    return (
        <div className={styles[theme.toLowerCase()]}>
            {noLayout ?
                <Comp {...props} /> :
                <Layout theme={theme.toLowerCase()}>
                    <Comp {...props} />
                </Layout>}
        </div>
    )
}

export const Routes = () => {
    const configs = {
        'tum_survey': tum_survey,
    }
    // eslint-disable-next-line no-restricted-globals
    const currentClient = (location.pathname.split('/')[1] || '').toLowerCase()
    const [currentRoutes, setCurrentRoutes] = useState([]);
    const [surveyMap, setSurveyMap] = useState(false)
    const [checkUserQuestionnaireStatus, setCheckUserQuestionnaireStatus] = useState(false)
    useEffect(() => {

        // Make language fixed if fixedLanguage is declared
        currentClient && tum_survey && tum_survey.fixedLanguage && setCookie('lang', tum_survey.fixedLanguage);

        // get routes according configuration
        setCurrentRoutes((tum_survey && tum_survey.routes) || configs['tum'].routes);
        setCheckUserQuestionnaireStatus(!!tum_survey && !!tum_survey.checkUserQuestionnaireStatus);

        // if multiple surveys, this should create a map
        setSurveyMap(tum_survey && tum_survey.surveyMap)

    }, [configs, currentClient])

    return (
        // Theme configs are concetrated on Layout.jsx
        <StateProvider>
            {/* ADD CHECKS TO ALTERNATIVE CUSTOMERS */}

            <Router>
                {checkUserQuestionnaireStatus && <CheckQuestionnaireStatus currentClient={'tum_survey'} />}
                {/* preload multiple surveys config */}
                {surveyMap && <LoadQuestionnaireConfigs surveyMap={surveyMap} />}
                <Switch>
                    {
                        currentRoutes.map(({ paths, Component, noLayout = false }, i) =>
                            paths.map(path => <Route exact key={i} path={path} render={withLayout(withRouter(Component), noLayout)} />)
                        )
                    }
                </Switch>
            </Router>
        </StateProvider>
    )
}

export default Routes