import React, { useState } from 'react'
import { Container, LoadBox } from "shared-components"

import styles from './LanguageSwitcher.module.scss'
import { changeLanguage, getLanguage } from "shared-utils/i18l";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLanguage } from '@fortawesome/free-solid-svg-icons'

const EN = 'en', DE = 'de';

const ChangeLanguageBtn = ({ lang }) => <button className={styles['change-language-btn']} onClick={() => changeLanguage(lang)} disabled={[lang].includes(getLanguage())}>{lang.toUpperCase()}</button>;
const ChangeLanguagesBtns = ({ languages }) => languages.map(lang => <ChangeLanguageBtn key={lang} lang={lang} />)

const LanguageSwitcher = () => {
    const [changeLanguge, setChangeLanguage] = useState(false)
    return (
        <div>
            <Container style={{ position: 'relative' }}>
                <div
                    className={styles.buttons}
                >
                    <LoadBox
                        loading={changeLanguge}
                        onClick={() => setChangeLanguage(false)}
                    />
                    <button
                        className={`${styles.buttons__mobile_menu} ${changeLanguge ? styles.buttons__mobile_menu__active : ''}`}
                        onClick={e => {
                            setChangeLanguage(true)
                        }}
                    >
                        <FontAwesomeIcon icon={faLanguage} style={{ pointerEvents: 'none' }} />
                    </button>
                    <div className={styles.buttons__container}>
                        <ChangeLanguagesBtns languages={[DE, EN]} />
                    </div>
                </div>
            </Container>
        </div>
    )
}
export default LanguageSwitcher