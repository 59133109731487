import React, { useContext } from 'react'
import { store } from 'store/hookStore'
import Login from 'containers/Login'

export const AuthorizedArea = ({ children, theme }) => {
    const globalState = useContext(store);
    const { state } = globalState;
    return state && state.user && state.user.access_token ? children : <Login customer={state.customer} />
}

export const protect = (Comp) => (props) => <AuthorizedArea><Comp {...props} /></AuthorizedArea>

export default AuthorizedArea
