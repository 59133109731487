
var versionAndDate = "ver 0.1, February 2017";

export const getAccessToken = (store) => { //Used
    const state = store.getState()
    return state && state['UserToken'] && state['UserToken']['accessToken']
}

var configProd = {
    BASE_URL: "https://api.production.ops.everskill.de",
    BRANDING_ASSETS: "https://branding.everskill.de",
    ASSETS: "https://asset.everskill.de",
    CLIENT_ID: 'everskill-app-trainerinterface',
    CLIENT_SECRET: "b63b12de336eeafd1dedb440b304ab41",
    // MIX_PANEL_TOKEN: "087fa59ae59eb8beef8d11eb8d49ed14", // TODO: set the proper Mix panel token for production
    version: versionAndDate
};

var configLocal = {
    BASE_URL: "http://localhost:8080",
    BRANDING_ASSETS: "https://branding-staging.everskill.de",
    ASSETS: "https://asset-staging.everskill.de",
    CLIENT_ID: 'everskill-app-trainerinterface',
    CLIENT_SECRET: "b63b12de336eeafd1dedb440b304ab41",
    // MIX_PANEL_TOKEN: "06a11891c9fe42286a1758b2d73b821f",
    // version: versionAndDate
};

var configStaging = {
    BASE_URL: "https://api.tum.unit.cloud",
    BRANDING_ASSETS: "https://branding.tum.unit.cloud",
    ASSETS: "https://asset.tum.unit.cloud",
    CLIENT_ID: 'everskill-app-web',
    CLIENT_SECRET: "TEkCgSfy4TlcdhnV9HIRbTdAMpfaeqVd",
    // MIX_PANEL_TOKEN: "06a11891c9fe42286a1758b2d73b821f",
    version: versionAndDate
};



export default {
    production: configProd,
    staging: configStaging,
    local: configLocal,
    development: configStaging,
};
