import normalizeChars from "./normalizeChars";

export const eventTracker_pretTraininig = ({
  property = "QTOOL",
  userUuid = "",
  id,
  brand = "",
}) => ({ category = "", action = "", label = "" }) => {
  // const log =  false
  const labelInfo = `${id || userUuid} ${label}`.trim();
  const categoryInfo = normalizeChars(
    `${property}_${brand ? "_" + brand : ""} ${category}`
  ).toUpperCase();
  const actionInfo = normalizeChars(action.replace(/everskill/gi, ""));
  window.userUUID = id || userUuid || null;
  if (window.ga && window.ga.getAll) {
    console.log({ categoryInfo, actionInfo, labelInfo });
    const tracker = window.ga.getAll()[0];
    if (tracker) {
      tracker.send("event", categoryInfo, actionInfo, labelInfo);
    }
  }
  // log && console.log({ categoryInfo, actionInfo, labelInfo});

  return true;
};

export default eventTracker_pretTraininig;
